/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';

function Card({
  children,
  className,
  onClick,
  title,
  closable,
  onClose,
  noShadow,
  border,
  badge,
  key,
}: {
  children: React.ReactNode;
  className?: string;
  title?: string;
  onClick?: () => void;
  closable?: boolean;
  onClose?: () => void;
  noShadow?: boolean;
  border?: boolean;
  badge?: string | React.ReactNode | React.ReactNode[];
  key?: any;
}) {
  return (
    <article
      className={`p-4 ${noShadow ? '' : 'shadow-md'}${
        border ? ' border-gray-200 md:border' : ''
      } rounded-[10px] relative ${className}`}
      onClick={onClick}
      key={key}
    >
      {!!badge && (
        <div className="absolute -top-6 left-2 rounded-lg rounded-bl-none rounded-br-none bg-blue-500 text-white font-medium text-xs px-2 py-1 ">
          {badge}
        </div>
      )}
      {closable && (
        <Button
          type="link"
          icon={<CloseOutlined />}
          className="text-gray-400 absolute top-2 right-2"
          onClick={() => (onClose ? onClose() : undefined)}
        />
      )}
      {title && <h2 className="text-lg font-semibold">{title}</h2>}
      {children}
    </article>
  );
}

export default Card;

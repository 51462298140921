import * as yup from 'yup';

/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Mail from '@assets/images/auth/mail.png';
import Card from '@components/Card';
import LoginEmailForm from '@components/Forms/Auth/LoginEmail';
import LoginPasswordForm from '@components/Forms/Auth/LoginPassword';
import Loader from '@components/Loader';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '@lib/auth';
import oyenAPI from '@lib/oyenAPI';
import LoginImage from '@public/login-image.svg';
import Logo from '@public/logo.svg';
import { gtm } from '@utils/gtm';
import { TFunction, useTranslation } from 'next-i18next';
import Image from 'next/image';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';
import { useCookies } from 'react-cookie';

const passwordSchema = (t: TFunction) =>
  yup.object({
    password: yup.string().required(t('enter_your_password')),
  });

const emailSchema = (t: TFunction) =>
  yup.object({
    email: yup
      .string()
      .required(t('enter_your_email'))
      .email(t('enter_valid_email')),
  });

const SignIn: React.FC = () => {
  const { signInWithEmail } = useAuth();
  const [passwordShown, setPasswordShown] = useState<boolean>(false);
  const [verified, setVerified] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [redirectCookie, setRedirectCookie] = useCookies(['redirect']);
  const { t: td } = useTranslation('dashboard');

  const {
    handleSubmit: handleSubmitEmail,
    register: registerEmail,
    formState: { isValid: isValidEmail, errors: errorsEmail },
    getValues: getEmail,
    setValue: setEmail,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(emailSchema(td)),
    defaultValues: {
      email: '',
    },
  });
  const {
    handleSubmit: handleSubmitPassword,
    register: registerPassword,
    getValues: getPassword,
    setValue: setPassword,
    formState: { isValid: isValidPassword, errors: errorsPassword },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(passwordSchema(td)),
    defaultValues: {
      password: '',
    },
  });

  const onSubmitEmail: SubmitHandler<{
    email: string;
  }> = async (payload) => {
    setError('');
    gtm.track('sign_in_email_submit');
    try {
      await oyenAPI().then((api) =>
        api
          .get(
            `/api/users/check?email=${encodeURIComponent(
              payload.email.toLowerCase()
            )}`
          )
          .then(({ data }) => {
            const { user } = data;
            if (user.firebaseId) {
              setPasswordShown(true);
            } else {
              setVerified(false);
            }
          })
      );
    } catch (err: any) {
      setError(td(err?.response?.data?.error));
      throw err;
    }
  };

  const onSubmitPassword: SubmitHandler<{
    password: string;
  }> = async (payload, e) => {
    e?.preventDefault();
    setError('');
    setLoading(true);
    try {
      const redirect = redirectCookie?.redirect ?? 'null';

      gtm.track('sign_in_password_submit');
      await signInWithEmail(
        getEmail('email').toLowerCase(),
        payload.password,
        redirect !== 'null' ? `../../${redirect}` : '/dashboard'
      );

      setRedirectCookie('redirect', null, {
        path: '/',
      });
    } catch (err: any) {
      console.error('login', err.message);
      gtm.track('sign_in_failed', {
        sign_in_fail_reason: err.message,
      });
      setError(err.message);
      // throw err;
    } finally {
      setPasswordShown(true);
      setLoading(false);
    }
    return false;
  };

  useEffect(() => {
    const sendEmail = async () => {
      if (!verified) {
        await oyenAPI().then((api) =>
          api.get('/api/users/create-password-email', {
            params: { email: getEmail('email').toLowerCase() },
          })
        );
      }
    };

    sendEmail().catch((err: any) => console.error(err));
  }, [verified]);

  if (!verified) {
    return (
      <div className="flex flex-col items-center w-full p-6">
        <h1 className="mt-6 text-xl font-semibold">{td('verify_account')}</h1>
        <div className="mt-4">
          <Image src={Mail} height={80} width={90} />
        </div>
        <div className="flex flex-col max-w-lg p-6 mt-6 text-center rounded-lg shadow bg-white">
          {td('check_email')}{' '}
          <span className="font-medium text-blue-500">{getEmail('email')}</span>{' '}
          {td('create_password_and_activate')}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-xl w-full mx-auto flex-grow flex flex-col md:mt-5 md:flex-grow-0 md:rounded">
      <Image src={LoginImage} height={235} width={150} />
      <Card className="bg-white">
        <Image src={Logo} height={25} width={90} />
        {loading ? (
          <div className="text-center">
            <Loader />
          </div>
        ) : !passwordShown ? (
          <LoginEmailForm
            getValues={getEmail}
            setValue={setEmail}
            handleSubmit={handleSubmitEmail}
            onSubmit={onSubmitEmail}
            register={registerEmail}
            errors={errorsEmail}
            error={error}
            isValid={isValidEmail}
          />
        ) : (
          <LoginPasswordForm
            getValues={getPassword}
            setValue={setPassword}
            onSubmit={onSubmitPassword}
            handleSubmit={handleSubmitPassword}
            register={registerPassword}
            errors={errorsPassword}
            error={error}
            isValid={isValidPassword}
            email={getEmail('email').toLowerCase()}
          />
        )}
      </Card>
    </div>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale = '' }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'dashboard'])),
    },
  };
};

export default SignIn;

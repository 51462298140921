import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import * as yup from 'yup';
import { gtm } from '@utils/gtm';
import { SubmitHandler, useForm } from 'react-hook-form';
import Confirmation from './Confirmation';
import InputWrapper, { InputName } from '../../Input';
import { Col, Row } from 'antd';
import Button from '../../Button';
import Link from 'next/link';
import LeftArrowIcon from '@assets/tsxs/LeftArrow';
import oyenAPI from '@lib/oyenAPI';
import { TFunction, useTranslation } from 'next-i18next';

const emailSchema = (t: TFunction) =>
  yup.object({
    email: yup
      .string()
      .required(t('enter_your_email'))
      .email(t('enter_valid_email')),
  });

const ForgotPassword = () => {
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [errorForgotPwd, setErrorForgotPwd] = useState<string>('');
  const { t: td } = useTranslation('dashboard');
  const { t: tc } = useTranslation('common');
  const {
    handleSubmit: handleSubmitForgotPwd,
    register: registerForgotPwd,
    setValue,
    getValues,
    formState: { isValid: isValidForgotPwd, errors: errorsForgotPwd },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(emailSchema(td)),
    defaultValues: {
      email: '',
    },
  });

  const onSubmitForgotPwd: SubmitHandler<{
    email: string;
  }> = async (payload) => {
    setErrorForgotPwd('');
    gtm.track('sign_in_forgot_password');

    await oyenAPI().then((api) =>
      api
        .get(
          `/api/users/reset-password-email?email=${encodeURIComponent(
            payload.email
          )}`
        )
        .then(async ({ data }) => {
          const { user } = data;
          if (user?.firebaseId) {
            setConfirmation(true);
          } else {
            setErrorForgotPwd(td('account_not_registered'));
          }
        })
    );
  };

  if (confirmation) {
    return (
      <Confirmation
        msg={td('new_password_email_sent')}
        callbackFunction={onSubmitForgotPwd}
        param={getValues('email')}
      />
    );
  }

  return (
    <>
      <p className="text-xl font-semibold mt-4">{td('reset_your_password')}</p>
      <p className="mb-4 mt-2">{td('reset_your_password_content')}</p>
      <form
        className="mt-4"
        onSubmit={handleSubmitForgotPwd(onSubmitForgotPwd)}
      >
        <Row gutter={[16, 12]}>
          <Col xl={24} xs={24}>
            <InputWrapper
              label={tc('email')}
              inputProps={{
                value: getValues('email'),
                setValue,
                input: InputName.Email,
                name: 'email',
                register: registerForgotPwd,
                placeholder: tc('email'),
              }}
              errors={errorsForgotPwd}
            />
          </Col>
        </Row>
        {errorForgotPwd && (
          <p className="input-text mt-2 text-red-500">{errorForgotPwd}</p>
        )}
        <footer className="actions lg:mt-4 mt-6">
          <Row gutter={[16, 12]} className="w-full flex-grow">
            <Col xl={24} xs={24}>
              <Button submit className="w-full" disabled={!isValidForgotPwd}>
                {td('email_me_recovery_link')}
              </Button>
            </Col>

            <Col
              xl={24}
              xs={24}
              className="flex flex-row justify-items-center items-center mt-2 cursor-pointer"
            >
              <LeftArrowIcon />
              <Link href="/">
                <span className="text-gray-500 font-normal ml-2 cursor-pointer">
                  {td('back_to_login')}
                </span>
              </Link>
            </Col>
          </Row>
        </footer>
      </form>
    </>
  );
};

export default ForgotPassword;

import * as React from "react"
const LeftArrowIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={13}
    fill="none"
    {...props}
  >
    <path
      fill="#555A65"
      d="M2.871 5.729H12V7.27H2.871l4.023 4.138-1.06 1.091L0 6.5l5.833-6 1.061 1.09L2.871 5.73Z"
    />
  </svg>
)
export default LeftArrowIcon

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Row, Col } from 'antd';
import {
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import Button from '@components/Button';
import InputWrapper, { InputName } from '@components/Input';
import ForgotPassword from './ForgotPassword';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

interface iLoginPasswordFormProps {
  onSubmit: any;
  getValues: (field?: 'password') => void;
  setValue: UseFormSetValue<{ password: string }>;
  handleSubmit: UseFormHandleSubmit<{ password: string }>;
  register: UseFormRegister<any>;
  errors: any;
  error: string;
  isValid: boolean;
  email: string;
}

function LoginPasswordForm({
  onSubmit,
  handleSubmit,
  setValue,
  getValues,
  isValid,
  errors,
  error,
  register,
  email,
}: iLoginPasswordFormProps) {
  const { t: td } = useTranslation('dashboard');

  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  if (forgotPassword) {
    return <ForgotPassword />;
  }

  return (
    <>
      <p className="text-xl font-semibold mt-4">
        {td('welcome_back_email', {
          email: email,
        })}
      </p>
      <div className="mt-4 flex flex-row">
        <p className="mr-1">{td('not_you?')}</p>
        <Link href="/">
          <a className="text-secondary hover:underline">
            {td('back_to_login')}
          </a>
        </Link>
      </div>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[16, 12]}>
          <Col xl={24} xs={24}>
            <InputWrapper
              label={td('password_label')}
              inputProps={{
                value: getValues('password'),
                input: InputName.Password,
                setValue,
                name: 'password',
                register,
                placeholder: 'Password',
              }}
              errors={errors}
            />
          </Col>
        </Row>
        <p
          className="hover:underline cursor-pointer text-secondary"
          onClick={() => setForgotPassword(true)}
        >
          {td('forgot_password')}
        </p>
        {error && <p className="input-text mt-2 text-red-500">{error}</p>}
        <footer className="actions lg:mt-4 mt-6 px-2">
          <Row gutter={[16, 12]} className="w-full flex-grow">
            <Col xl={24} xs={24}>
              <Button
                className="w-full"
                disabled={!isValid}
                onClick={handleSubmit(onSubmit)}
              >
                {td('submit')}
              </Button>
            </Col>
          </Row>
        </footer>
      </form>
      <div className="mt-4 flex flex-row">
        <p className="mr-1">{td('need_help')}</p>

        <Link href="https://help.oyen.my/en">
          <a className="text-secondary hover:underline">
            {td('visit_our_help_center')}
          </a>
        </Link>
      </div>
    </>
  );
}

export default LoginPasswordForm;

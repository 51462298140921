/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import Image from 'next/image';
import Mail from '@assets/images/auth/mail.png';
import { gtm } from '@utils/gtm';
import { useTranslation } from 'next-i18next';
import LeftArrowIcon from '@assets/tsxs/LeftArrow';
import Link from 'next/link';

interface iConfirmationProps {
  msg: string;
  callbackFunction: (params?: any) => any;
  param: any;
}

const Confirmation: React.FC<iConfirmationProps> = ({
  msg,
  callbackFunction,
  param,
}) => {
  const { t: td } = useTranslation('dashboard');
  return (
    <div className="text-center">
      <Image src={Mail} height={80} width={90} />
      <p className="text-xl font-semibold mt-4">{td('check_your_email')}</p>
      <p className="mt-4">{msg}</p>
      <div className="mt-4 flex flex-row justify-center">
        <p className="mr-1">{td('did_not_receive_email')}</p>
        <div className="font-medium underline text-secondary cursor-pointer">
          <p
            onClick={() => {
              gtm.track('Verification Email Resend Clicked');
              callbackFunction(param);
            }}
          >
            {td('send_again')}
          </p>
        </div>
      </div>
      <div className="mt-4 flex flex-row justify-center">
        <p className="mr-1">{td('need_help')}</p>
        <div className="font-medium underline text-secondary">
          <a href="https://help.oyen.my/en">{td('visit_our_help_center')}</a>
        </div>
      </div>

      <footer className="actions lg:mt-4 mt-6 flex flex-row justify-center ">
          
          <div className='flex flex-row justify-center items-center'>
              <LeftArrowIcon />
              <Link href="/">
                <span className="text-gray-500 font-normal ml-2 cursor-pointer">
                  {td('back_to_login')}
                </span>
              </Link>
              </div>
        </footer>
    </div>
  );
};

export default Confirmation;
